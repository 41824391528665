import * as React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
} from "@material-ui/core";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import config from "customConfig";
import ConfirmationDialog from "components/ConfirmationDialog";
import { API } from "@aws-amplify/api";
import * as mutations from "graphql/mutations";
import { useSnackbar } from "notistack";

interface SpeakingRecordListProps {
  recordList: any[];
  setRecordList: (record: any) => void;
}

const SpeakingRecordList: React.FC<SpeakingRecordListProps> = ({
  recordList,
  setRecordList,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = React.useState<any>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const player = React.createRef<HTMLAudioElement>();
  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    if (index === selected) {
      setSelected(null);
    } else {
      setSelected(index);
    }
  };
  const handleDelete = async () => {
    const toDeletedItem = recordList[selected];
    try {
      await API.graphql({
        query: mutations.deleteSpeakingRecord,
        variables: { input: { id: toDeletedItem.id } },
      });
      enqueueSnackbar("Successfully deleted the record", {
        variant: "success",
      });
      setRecordList((rl: any) =>
        rl.filter((r: any) => r.id !== toDeletedItem.id)
      );
      setSelected(null);
    } catch (error) {
      enqueueSnackbar("Connection problem, please try again later", {
        variant: "error",
      });
      console.log("delete speakingRecord error", error);
    }
  };
  if (recordList.length === 0) return null;
  return (
    <List>
      {typeof selected === "number" && (
        <audio
          ref={player}
          src={config.awsUrl + recordList[selected].url}
          controls
        />
      )}

      {recordList.map((record: any, index: number) => (
        <ListItem
          button
          onClick={(event) => handleListItemClick(event, index)}
          selected={selected === index}
          key={record.id}
        >
          <ListItemAvatar>
            <Avatar>
              <GraphicEqIcon color="primary" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={record.url.split("/").pop()} />
          {selected === index && (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="play"
                onClick={() => player.current.play()}
              >
                <PlayArrowIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="pause"
                onClick={() => player.current.pause()}
              >
                <PauseIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => setOpen(true)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
      <ConfirmationDialog
        open={open}
        message="Are you sure you want to delete this audio record?"
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          handleDelete();
          setOpen(false);
        }}
      />
    </List>
  );
};

export default SpeakingRecordList;
