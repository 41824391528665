import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import PracticeList from "components/Practice/PracticeList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { API } from "@aws-amplify/api";
import * as mutations from "graphql/mutations";
import customConfig from "customConfig";

const { subjects } = customConfig;

export interface AddPracticeDialogProps {
  open: boolean;
  handleClose: () => void;
  append: (data: any) => void;
}

const AddPracticeDialog: React.FC<AddPracticeDialogProps> = ({
  open,
  handleClose,
  append,
}) => {
  const history = useHistory();
  const [data, setData] = React.useState<any>(null);
  const handleSubmit = async () => {
    if (data.list) {
      const { name, list, type } = data;
      const inputList = list.map((q: any) => {
        delete q.tableData;
        delete q.createdAt;
        delete q.updatedAt;
        return q;
      });
      const input = {
        name,
        type,
        questions: inputList,
        typeName: "SpeakingList",
      };
      try {
        const {
          data: { createSpeakingList: returnedData },
        } = (await API.graphql({
          query: mutations.createSpeakingList,
          variables: { input },
        })) as any;
        const cleanedData = {
          id: returnedData.id,
          typeName: returnedData.typeName,
          type: returnedData.type,
          name: returnedData.name,
        };
        append(cleanedData);
        handleClose();
      } catch (error) {
        console.log("create speaking list error", error);
      }
    } else {
      append(data);
      handleClose();
    }
  };

  const handlePreview = (_event: any, rowData: any) => {
    const { typeName, id } = rowData;
    const subject = subjects[typeName];
    history.push(`/practice/${subject}?typeName=${typeName}&id=${id}`);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, name: event.target.value });
  };
  const handleSelect = (_event: any, rowData: any) => {
    let name = ["Essay", "Graph", "Letter"].includes(rowData.typeName)
      ? rowData.id
      : null;
    const cleanedData = {
      id: rowData.id,
      typeName: rowData.typeName,
      type: rowData.type ?? rowData.types ?? "",
      name: name ?? rowData.name ?? rowData.prompt ?? rowData.question ?? "",
    };
    setData(cleanedData);
  };
  const canSubmit = data ? !!data.name || !!data.id : false;

  const action = [
    {
      icon: () => <AddCircleOutlineIcon />,
      tooltip: "choose Practice",
      onClick: handleSelect,
    },
    {
      icon: () => <VisibilityIcon />,
      tooltip: "preview",
      onClick: handlePreview,
    },
  ];
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Select Practice</DialogTitle>
      <DialogContent>
        <PracticeList action={action} handleSelection={setData} />

        {data ? (
          data.list ? (
            <>
              <TextField
                label="Name"
                value={data.name ?? ""}
                onChange={handleTextChange}
                variant="outlined"
              />
              {data.list.map((q: any) => (
                <span key={q.id} style={{ display: "block" }}>
                  {q.question}
                </span>
              ))}
            </>
          ) : (
            <p>
              Selected Practice : <b>{data.name ? data.name : data.id}</b>
            </p>
          )
        ) : (
          <p>Please Select Practice</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!canSubmit}>
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPracticeDialog;
