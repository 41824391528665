import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { ThemeProvider } from "@material-ui/core/styles";
import AuthProvider from "context/auth.context";
import theme from "theme";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import NotificationProvider from "context/notification.context";

Amplify.configure(awsExports);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <NotificationProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
          <CssBaseline />
        </BrowserRouter>
      </AuthProvider>
    </NotificationProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
