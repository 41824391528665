import React from "react";
import { useLocation } from "react-router-dom";
import { API } from "@aws-amplify/api";
import * as mutations from "graphql/mutations";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

const updateHomework = (input) => {
  return API.graphql({
    query: mutations.updateHomework,
    variables: {
      input,
    },
  });
};

const useHomework = () => {
  const mutation = useMutation(updateHomework);
  const { enqueueSnackbar } = useSnackbar();
  let onSubmit;
  let submittedAnswers;
  let time;
  let title;
  const location = useLocation();
  const { hw, item } = location?.state || {};
  const [startTime, setStartTime] = React.useState(() => Date.now());
  React.useEffect(() => () => setStartTime(null), [setStartTime]);
  if (hw && item) {
    time = item.timeLimit;
    submittedAnswers = item.submittedAnswers
      ? JSON.parse(item.submittedAnswers)
      : null;
    title = "homework";
    onSubmit = async (answers) => {
      const newPracticeList = hw.practiceList.map((p) => {
        let temp = { ...p };
        if (temp.id === item.id) {
          temp["submittedAnswers"] = JSON.stringify(answers);
          temp["startTime"] = startTime.toString();
          temp["endTime"] = Date.now().toString();
        }
        return temp;
      });
      try {
        enqueueSnackbar("loading...", {
          variant: "info",
          action: () => (
            <CircularProgress style={{ color: "white" }} size={20} />
          ),
          autoHideDuration: 1000,
        });
        await mutation.mutateAsync({
          id: hw.id,
          practiceList: newPracticeList,
        });
        enqueueSnackbar("Successfully submitted your answers", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(mutation.error.message, { variant: "error" });
      }
    };
  }

  return { onSubmit, submittedAnswers, time, title };
};

export default useHomework;
