import { API } from "@aws-amplify/api";
import * as React from "react";
import AudioRecorder from "./AudioRecorder";
import * as queries from "graphql/queries";
import SpeakingRecordList from "./SpeakingRecordList";

interface SpeakingQuestionProps {
  data: any;
  index: number;
  username: string;
}

const SpeakingQuestion: React.FC<SpeakingQuestionProps> = ({
  data,
  index,
  username,
}) => {
  const [recordList, setRecordList] = React.useState<any>([]);
  const { id: questionID, typeName: questionTypeName } = data;

  React.useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            byUserTypeTypeID: { items },
          },
        } = (await API.graphql({
          query: queries.byUserTypeTypeId,
          variables: {
            user: username,
            typeTypeID: { eq: { type: questionTypeName, typeID: questionID } },
            limit: 100,
          },
        })) as any;
        setRecordList(items);
      } catch (error) {
        console.log("list recordlist error", error);
      }
    })();
    return () => {
      setRecordList([]);
    };
  }, [questionID, questionTypeName, username]);
  return (
    <>
      <p>{`${index}. ${data.question}`}</p>
      <SpeakingRecordList
        recordList={recordList}
        setRecordList={setRecordList}
      />
      <AudioRecorder
        username={username}
        questionTypeName={questionTypeName}
        questionID={questionID}
        setRecordList={setRecordList}
      />
    </>
  );
};

export default SpeakingQuestion;
