import * as React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import { useAuthValue } from "context/auth.context";
import MaterialTable from "material-table";
import { tableIcons } from "utilities/materialTableIcon";
import { API } from "@aws-amplify/api";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";
import { format } from "date-fns";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import useAmplifyQuery from "hooks/useAmplifyQuery";
import LinearProgress from "@material-ui/core/LinearProgress";

export interface HomeworkTableProps {}

const checkHWStatus = (data: any) => {
  const currentDate = new Date().getTime();
  const dueDate = new Date(data.dueDate).getTime();
  if (data.status) {
    return data.status;
  } else {
    if (currentDate > dueDate) {
      API.graphql({
        query: mutations.updateHomework,
        variables: { input: { id: data.id, status: "overdue" } },
      });
      return "overdue";
    }
    if (
      data.practiceList.every((practice: any) => !practice.submittedAnswers)
    ) {
      return "not start";
    }
    if (
      data.practiceList.every((practice: any) => !!practice.submittedAnswers)
    ) {
      API.graphql({
        query: mutations.updateHomework,
        variables: { input: { id: data.id, status: "completed" } },
      });
      return "completed";
    }
    return "unfinished";
  }
};

const adminTableColumn = [
  { title: "Title", field: "title" },
  {
    title: "Student",
    field: "student",
  },
  {
    title: "Due Date",
    field: "dueDate",
    render: (data: any) =>
      format(new Date(data.dueDate), "yyyy-MM-dd hh:mm aa"),
  },
  {
    title: "Status",
    field: "status",
    render: (data: any) => checkHWStatus(data),
  },
];

const studentTableColumn = adminTableColumn.filter(
  (col: any) => col.title !== "Student"
);

const options = {
  actionsColumnIndex: -1,
  sorting: true,
  pageSize: 10,
};

const HomeworkTable: React.FC<HomeworkTableProps> = () => {
  const { isAdmin } = useAuthValue();
  const history = useHistory();
  const { value, status } = useAmplifyQuery(queries.listHomeworks, {
    limit: 1000,
  });

  const actions = isAdmin
    ? [
        {
          icon: () => <ArrowForwardIcon />,
          tooltip: "go to homework detail page",
          onClick: (_event: any, rowData: any) =>
            history.push({
              pathname: "/homework/detail",
              state: { hwData: rowData },
            }),
        },
        {
          icon: () => <AddCircleIcon />,
          tooltip: "Add Homework",
          isFreeAction: true,
          onClick: () => history.push("/homework/create"),
        },
      ]
    : [
        {
          icon: () => <ArrowForwardIcon />,
          tooltip: "go to homework detail page",
          onClick: (_event: any, rowData: any) =>
            history.push({
              pathname: "/homework/detail",
              state: { hwData: rowData },
            }),
        },
      ];
  const data = value
    ? value.data.listHomeworks.items.sort(
        (a: any, b: any) =>
          new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
      )
    : [];
  const columns = isAdmin ? adminTableColumn : studentTableColumn;
  return (
    <>
      {status === "pending" && <LinearProgress />}
      <MaterialTable
        title="Homework List"
        icons={tableIcons}
        columns={columns}
        data={data}
        actions={actions}
        options={options}
      />
    </>
  );
};

export default HomeworkTable;
