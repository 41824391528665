import * as React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { API } from "@aws-amplify/api";
import * as queries from "graphql/queries";
import { useSnackbar } from "notistack";

interface ExamModelListProps {
  handleChange: (examModelID: any) => void;
}

const ExamModelList: React.FC<ExamModelListProps> = ({ handleChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    (async () => {
      try {
        const data = (await API.graphql({
          query: queries.listExamModels,
          variables: { limit: 1000 },
        })) as any;
        setOptions(data.data.listExamModels.items);
      } catch (error) {
        console.log("list Exam Model error", error);
        enqueueSnackbar("connection error, please try again later", {
          variant: "error",
        });
      }
    })();
  }, [enqueueSnackbar]);
  return (
    <Autocomplete
      value={value}
      onChange={(_event: any, newValue: any) => {
        setValue(newValue);
        if (newValue !== null) {
          handleChange(newValue.id);
        } else {
          handleChange(newValue);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={(option) => option.name}
      options={options}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label="Choose ExamModel" variant="outlined" />
      )}
    />
  );
};

export default ExamModelList;
