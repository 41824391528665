import * as React from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Storage, API } from "aws-amplify";
import * as mutations from "graphql/mutations";
import { useAuthValue } from "context/auth.context";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Spinner from "components/Spinner";
import UserAvatar from "components/UserAvatar";
import { useSnackbar } from "notistack";

export interface ChangeProfilePictureDialogProps {
  toggleCPPD: any;
  openCPPD: boolean;
}

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const ChangeProfilePictureDialog: React.FC<ChangeProfilePictureDialogProps> = ({
  toggleCPPD,
  openCPPD,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, setUser } = useAuthValue();
  const [loading, setLoading] = React.useState(false);
  const [fileName, setFileName] = React.useState<any>();
  const [image, setImage] = React.useState<any>();
  const [cropper, setCropper] = React.useState<any>();

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setFileName(files[0].name);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (typeof cropper !== "undefined") {
      let croppedFile = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        fileName
      );
      try {
        const data: any = await Storage.put(
          `resource/${user.username}/${croppedFile.name}`,
          croppedFile,
          {
            // @ts-ignore
            useAccelerateEndpoint: true,
          }
        );
        setUser({ ...user, picture: data.key });
        await API.graphql({
          query: mutations.updateUser,
          variables: { input: { id: user.id, picture: data.key } },
        });
        handleCancel();
      } catch (error) {
        console.log("saving image error", error);
      }
    } else {
      enqueueSnackbar("please select file", { variant: "info" });
    }
    setLoading(false);
  };
  const handleCancel = () => {
    setFileName("");
    setImage(undefined);
    setCropper(undefined);
    toggleCPPD();
  };

  return (
    <Dialog
      open={openCPPD}
      onClose={toggleCPPD}
      aria-labelledby="change-avatar-dialog-title"
    >
      {loading && <Spinner />}
      <DialogTitle id="change-avatar-dialog-title">Change Avatar</DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            {image ? (
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                <div
                  className="img-preview"
                  style={{
                    width: "100px",
                    height: "100px",
                    overflow: "hidden",
                  }}
                />
              </div>
            ) : (
              <UserAvatar
                user={user}
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </Grid>
          <Grid item>
            <input type="file" accept="image/*" onChange={onChange} />
          </Grid>
          <Grid item>
            <Cropper
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={image === undefined}
        >
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeProfilePictureDialog;
