import * as React from "react";
import { Storage } from "aws-amplify";
import { useSnackbar } from "notistack";
import { Button, Grid, Typography } from "@material-ui/core";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import DownloadFile from "./DownloadFile";

export interface AWSFileInputProps {
  upload: (url: string) => void;
  title?: string;
  path?: string;
  fileType?: string;
  fileKey?: string;
}

const AWSFileInput: React.FC<AWSFileInputProps> = ({
  upload,
  fileType,
  path,
  fileKey,
  title,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState<any>(0);
  const [storageKey, setStorageKey] = React.useState<any>(fileKey);
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    setLoading(true);
    try {
      const { key }: any = await Storage.put(`${path}/${file.name}`, file, {
        contentType: fileType,
        progressCallback(progress: any) {
          setLoaded((progress.loaded / progress.total) * 100);
        },
        // @ts-ignore
        useAccelerateEndpoint: true,
      });
      upload(key);
      setStorageKey(key);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoading(false);
  };
  const handleDelete = async () => {
    setLoading(true);
    try {
      await Storage.remove(storageKey, {
        // @ts-ignore
        useAccelerateEndpoint: true,
      });
      upload(null);
      setStorageKey(null);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
    setLoading(false);
  };
  if (loading) return <CircularProgressWithLabel value={loaded} />;
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Typography display="inline">
          <b>{title}: &nbsp;</b>
        </Typography>
        <DownloadFile storageKey={storageKey} />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          component="label"
          size="small"
          color="secondary"
        >
          Upload File
          <input
            type="file"
            hidden
            accept={fileType}
            onChange={(e) => handleFileUpload(e)}
          />
        </Button>
      </Grid>
      <Grid item>
        {storageKey && (
          <Button size="small" onClick={handleDelete}>
            Delete
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

AWSFileInput.defaultProps = {
  path: "",
  fileType:
    ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf, audio/*,video/*",
  title: "File",
};
export default AWSFileInput;
